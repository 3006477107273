// src/PrivateRoute.js

import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, checkSubscriptionStatus } = useContext(AuthContext);
  const [statusChecked, setStatusChecked] = useState(false);
  const [subscriptionValid, setSubscriptionValid] = useState(false);

  useEffect(() => {
    const verifySubscription = async () => {
      const isValid = await checkSubscriptionStatus();
      setSubscriptionValid(isValid);
      setStatusChecked(true);
    };
    verifySubscription();
  }, [checkSubscriptionStatus]);

  if (!statusChecked) {
    // Show a loading indicator while checking subscription status
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Not authenticated, redirect to subscription page
    return <Navigate to="/subscription" />;
  }

  if (!subscriptionValid) {
    // Subscription expired, redirect to subscription page
    return <Navigate to="/subscription" />;
  }

  // Authenticated and subscription valid
  return children;
};

export default PrivateRoute;
