// src/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to check subscription status
  const checkSubscriptionStatus = async () => {
    try {
      const response = await fetch('/api/check-subscription', { credentials: 'include' });
      const data = await response.json();
      return data.isValid; // Returns true if subscription is valid
    } catch (error) {
      console.error('Subscription check error:', error);
      return false;
    }
  };

  useEffect(() => {
    // Check authentication status on component mount
    const checkAuth = async () => {
      try {
        const res = await fetch('/api/check-auth', { credentials: 'include' });
        const data = await res.json();
        setIsAuthenticated(data.isAuthenticated);
      } catch (err) {
        console.error('Auth check error:', err);
      }
    };

    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, checkSubscriptionStatus }}>
      {children}
    </AuthContext.Provider>
  );
};
