// InsightsComponent.js

import React, { useEffect, useState } from 'react';
import { 
  fetchDailyMetrics, 
  fetchUnderMintCount, 
  fetchOwnersAggregatedStatsHistorical,
  fetchPPTDH,
  fetchMonthlyPPTDHAverages
} from './apiService';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import './InsightsComponent.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const InsightsComponent = () => {
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [holdersChartData, setHoldersChartData] = useState(null);
  const [historicalData, setHistoricalData] = useState(null);
  const [pptdhChartData, setPptdhChartData] = useState(null);
  const [monthlyPPTDHData, setMonthlyPPTDHData] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [metricsResponse, mintCountResponse, historicalResponse, pptdhResponse, monthlyPPTDHResponse] = await Promise.all([
          fetchDailyMetrics(),
          fetchUnderMintCount(),
          fetchOwnersAggregatedStatsHistorical(),
          fetchPPTDH(),
          fetchMonthlyPPTDHAverages()
        ]);

        setData(metricsResponse.data);
        setHistoricalData(historicalResponse.data);

        // Set up cards chart data
        const dates = mintCountResponse.data.map(item => new Date(item.date).toLocaleDateString());
        const lowFloorCounts = mintCountResponse.data.map(item => item.low_floor);
        const totalCounts = mintCountResponse.data.map(item => item.count);

        setChartData({
          labels: dates,
          datasets: [
            {
              label: 'Cards Under Mint price',
              data: lowFloorCounts,
              borderColor: 'red',
              backgroundColor: 'red',
              pointRadius: 3,
              tension: 0.1
            },
            {
              label: 'No Of Cards Available',
              data: totalCounts,
              borderColor: 'green',
              backgroundColor: 'green',
              pointRadius: 3,
              tension: 0.1
            }
          ]
        });

        // Set up holders chart data
        const holdersDates = historicalResponse.data.map(item => new Date(item.fetch_timestamp).toLocaleDateString());
        const holdersCounts = historicalResponse.data.map(item => item.holders_any_tokenid);

        setHoldersChartData({
          labels: holdersDates,
          datasets: [
            {
              label: 'Holders Count',
              data: holdersCounts,
              borderColor: 'red',
              backgroundColor: 'red',
              pointRadius: 3,
              tension: 0.1
            }
          ]
        });

        // Process PPTDH data
        setPptdhChartData({
          labels: pptdhResponse.data.map(item => item.date),
          datasets: [
            {
              label: 'PPTDH',
              data: pptdhResponse.data.map(item => item.pptdh),
              borderColor: 'red',
              backgroundColor: 'red',
              pointRadius: 3,
              tension: 0.1
            }
          ]
        });

        // Set up monthly PPTDH chart data
        setMonthlyPPTDHData({
          labels: monthlyPPTDHResponse.data.map(item => item.month),
          datasets: [
            {
              label: 'Average Monthly PPTDH',
              data: monthlyPPTDHResponse.data.map(item => item.avg_pptdh),
              borderColor: 'blue',
              backgroundColor: 'blue',
              pointRadius: 3,
              tension: 0.1,
              yAxisID: 'y'
            },
            {
              label: 'Number of Sales',
              data: monthlyPPTDHResponse.data.map(item => item.sale_count),
              borderColor: 'red',
              backgroundColor: 'red',
              pointRadius: 3,
              tension: 0.1,
              yAxisID: 'y1'
            }
          ]
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAllData();
  }, []);

  // Helper function to calculate changes over time periods
  const calculateChange = (currentValue, historicalValue) => {
    if (!currentValue || !historicalValue) return null;
    const change = currentValue - historicalValue;
    const arrow = change >= 0 ? '↑' : '↓';
    return (
      <>
        {historicalValue}{' '}
        <span className={change >= 0 ? 'arrow-up' : 'arrow-down'}>
          {arrow}{Math.abs(change)}
        </span>
      </>
    );
  };

  // Helper function to get historical value for a specific time period
  const getHistoricalValue = (metric, days) => {
    if (!historicalData || historicalData.length === 0) return null;
    
    const today = new Date();
    const targetDate = new Date(today.getTime() - (days * 24 * 60 * 60 * 1000));
    
    // Find the closest date entry
    const historicalEntry = historicalData
      .sort((a, b) => Math.abs(new Date(a.fetch_timestamp) - targetDate) - Math.abs(new Date(b.fetch_timestamp) - targetDate))[0];

    switch(metric) {
      case 'Holders Count':
        return historicalEntry.holders_any_tokenid;
      case 'Full Set Holders':
        return historicalEntry.holders_all_tokenid;
      case 'Hold Min One Card per SZN':
        return historicalEntry.holders_any_token_each_season;
      default:
        return null;
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 320,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white'
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'white'
        }
      },
      title: {
        display: true,
        text: 'Comparison of Cards Under Mint Price and Total Cards Available',
        color: 'white',
        font: {
          size: 16
        }
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].label);
            return date.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            });
          }
        }
      }
    }
  };

  const holdersChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        min: 9700,
        max: 10000,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white'
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'white'
        }
      },
      title: {
        display: true,
        text: 'No Of Holders Holding Any 6529 Meme Card',
        color: 'white',
        font: {
          size: 16
        }
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].label);
            return date.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            });
          }
        }
      }
    }
  };

  const pptdhChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white'
        },
        title: {
          display: true,
          text: 'PPTDH',
          color: 'white',
          font: {
            size: 12
          }
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45,
          callback: function(value, index, values) {
            const date = new Date(this.getLabelForValue(value));
            return date.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            });
          }
        },
        title: {
          display: true,
          text: 'Dates',
          color: 'white',
          font: {
            size: 12
          }
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'white'
        }
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].label);
            return date.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            });
          },
          label: function(context) {
            return `PPTDH: ${context.parsed.y.toFixed(4)}`;
          }
        }
      },
      title: {
        display: true,
        text: 'PPTDH Over Time',
        color: 'white',
        font: {
          size: 16
        }
      }
    }
  };

  const monthlyPPTDHChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'PPTDH',
          color: 'white'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Number of Sales',
          color: 'white'
        },
        grid: {
          drawOnChartArea: false
        },
        ticks: {
          color: 'white'
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'white'
        }
      },
      title: {
        display: true,
        text: [ 'Average Monthly PPTDH'],
        color: 'white',
        font: {
          size: 16
        },
        padding: {
          bottom: 10
        }
      }
    }
  };

  if (!data) {
    return <div className="insights-container">Loading...</div>;
  }

  const { interval_metrics, single_metrics } = data;

  // Prepare data for the first table (Sales)
  const timeIntervals = ['24h', '7d', '30d', 'ytd'];
  const intervalMetricsRows = Object.entries(interval_metrics).map(([metricName, timeData]) => {
    const row = { metricName };
    timeIntervals.forEach(interval => {
      row[interval] = timeData[interval] || 'N/A';
    });
    return row;
  });

  // Define the order of single metrics
  const singleMetricOrder = [
    'Holders Count',
    'Full Set Holders',
    'Hold Min One Card per SZN',
    'Lowest PPTDH'
  ];
  const singleMetricsRows = singleMetricOrder.map(metricName => ({
    metricName,
    metricValue: single_metrics[metricName] || 'N/A',
  }));

  // Helper function to split name and amount for "Highest Sale" and "Highest Revenue Card"
  const splitNameAndAmount = (value) => {
    const match = value.match(/^(.+)\s+\((.+)\)$/);
    if (match) {
      const idMatch = match[1].match(/\d+/);
      const id = idMatch ? idMatch[0] : null;
      const name = id ? match[1].replace(id, '').trim() : match[1].trim();
      return (
        <>
          {id && <div>ID: {id}</div>}
          <div>{name}</div>
          <div>({match[2]})</div>
        </>
      );
    } else {
      return value;
    }
  };

  return (
    <div className="insights-container">
      <h1 className="main-header">Collection Insights</h1>

      {/* First Table: Sales */}
      <h2 className="section-header">Sales</h2>
      <table className="metrics-table">
        <thead>
          <tr>
            <th>Metric</th>
            {timeIntervals.map(interval => (
              <th key={interval}>{interval}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {intervalMetricsRows.map((row, index) => (
            <tr key={index} className={index % 2 !== 0 ? 'even-row' : ''}>
              <td className="metric-name-cell">{row.metricName}</td>
              {timeIntervals.map(interval => (
                <td key={interval}>
                  {["Highest Sale", "Highest Revenue Card"].includes(row.metricName) && row[interval] && row[interval].includes('(') ? (
                    splitNameAndAmount(row[interval])
                  ) : (
                    row[interval]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Second Table: Hodlers & PPTDH */}
      <h2 className="section-header">Hodlers & PPTDH</h2>
      <table className="metrics-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th>24h</th>
            <th>7d</th>
            <th>30d</th>
          </tr>
        </thead>
        <tbody>
          {singleMetricsRows.map((row, index) => {
            // Skip Lowest PPTDH as it doesn't need the time-based columns
            if (row.metricName === 'Lowest PPTDH') {
              return (
                <tr key={index} className={index % 2 !== 0 ? 'even-row' : ''}>
                  <td className="metric-name-cell">{row.metricName}</td>
                  <td colSpan="3">
                    {Array.isArray(row.metricValue) ? (
                      <div className="pptdh-values">
                        {row.metricValue.map((item, idx) => (
                          <div key={idx}>
                            <strong>Card No: {item.ID}</strong> - {item.Name} (PPTDH: {item.PPTDH})
                          </div>
                        ))}
                      </div>
                    ) : (
                      row.metricValue
                    )}
                  </td>
                </tr>
              );
            }

            // Get current and historical values
            const currentValue = historicalData?.[historicalData.length - 1]?.[
              row.metricName === 'Holders Count' ? 'holders_any_tokenid' :
              row.metricName === 'Full Set Holders' ? 'holders_all_tokenid' :
              'holders_any_token_each_season'
            ];

            const day = getHistoricalValue(row.metricName, 1);
            const week = getHistoricalValue(row.metricName, 7);
            const month = getHistoricalValue(row.metricName, 30);

            return (
              <tr key={index} className={index % 2 !== 0 ? 'even-row' : ''}>
                <td className="metric-name-cell">{row.metricName}</td>
                <td>{day && currentValue ? calculateChange(currentValue, day) : 'N/A'}</td>
                <td>{week && currentValue ? calculateChange(currentValue, week) : 'N/A'}</td>
                <td>{month && currentValue ? calculateChange(currentValue, month) : 'N/A'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Under Mint Price Chart */}
      <div className="chart-section">
        <h2 className="chart-section-title">Cards Under Mint Price vs Total Cards Available</h2>
        <div className="chart-container">
          {chartData && <Line data={chartData} options={chartOptions} />}
        </div>
      </div>

      {/* Holders Chart */}
      <div className="chart-section">
        <h2 className="chart-section-title">Number of Holders Over Time</h2>
        <div className="chart-container">
          {holdersChartData && <Line data={holdersChartData} options={holdersChartOptions} />}
        </div>
      </div>

      {/* PPTDH Chart */}
      <div className="chart-section">
        <h2 className="chart-section-title">Lowest PPTDH Over Time</h2>
        <div className="chart-container">
          {pptdhChartData && <Line data={pptdhChartData} options={pptdhChartOptions} />}
        </div>
      </div>

      {/* Monthly PPTDH Chart */}
      {monthlyPPTDHData && (
        <div className="chart-section">
          <h2 className="chart-section-title">Monthly Average PPTDH based on Secondary Sales</h2>
          <div className="chart-container">
            <Line data={monthlyPPTDHData} options={monthlyPPTDHChartOptions} />
          </div>
        </div>
      )}

      {/* Contact section */}
      <div className="contact-section">
        <p>If you would like to see any other collection metrics here, please contact us.</p>
      </div>
    </div>
  );
};

export default InsightsComponent;
