// src/Navbar.js

import React, { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import { AuthContext } from './AuthContext';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setActiveDropdown(null);
  };

  const toggleDropdown = (menu) => {
    if (activeDropdown === menu) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(menu);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        {/* Hamburger Menu Icon for Mobile */}
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        {/* Navigation Menu */}
        <ul className={`menu ${isMenuOpen ? 'active' : ''}`}>
          {/* 1. Home Link */}
          <li>
            <NavLink
              to="/"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Home
            </NavLink>
          </li>

          {/* 2. Sales Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'sales' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('sales')}>Sales</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/sales-summary"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales Summary
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sales-analytics"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales Analytics
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sales-all-time"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales History
                </NavLink>
              </li>
            </ul>
          </li>

          {/* 3. TDH/PPTDH Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'tdh' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('tdh')}>TDH/PPTDH</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/tdh-table"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Data Table
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/tdh-stats"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Historical Stats
                </NavLink>
              </li>
            </ul>
          </li>

          {/* 4. Hodlers Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'hodlers' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('hodlers')}>Hodlers</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/hodlers/aggregated"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Aggregated
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hodlers/supply"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Hodlers Supply
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hodlers/supply-enhanced"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Hodlers Supply Enhanced
                </NavLink>
              </li>
            </ul>
          </li>

          {/* 5. Insights Link */}
          <li>
            <NavLink
              to="/insights"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Insights
            </NavLink>
          </li>

          {/* 6. Services Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'services' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('services')}>Services</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/portfolio"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Portfolio
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/notifications"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Notifications
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/subscription"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Subscriptions
                </NavLink>
              </li>
              {!isAuthenticated && (
                <li>
                  <NavLink
                    to="/login"
                    onClick={closeMenu}
                    className={({ isActive }) => (isActive ? 'active-link' : '')}
                  >
                    Login
                  </NavLink>
                </li>
              )}
            </ul>
          </li>

          {/* 7. About Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'about' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('about')}>About</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/about"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Authentication Links */}
          {isAuthenticated ? (
            <>
              {/* Change Password Link */}
              <li>
                <NavLink
                  to="/change-password"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Change Password
                </NavLink>
              </li>
              {/* Logout Link */}
              <li>
                <button className="logout-button" onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </>
          ) : null /* Removed the separate Login link */ }
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
