// FooterComponent.js

import React from 'react';
import { Link } from 'react-router-dom';
import './FooterComponent.css';

const FooterComponent = () => {
  return (
    <footer className="footer-container">
      <div className="footer-inner">
        <p className="creator-info">Created by Vantekai</p>
        
        <div className="social-links">
          <a href="https://x.com/Vantekai_" target="_blank" rel="noopener noreferrer">
            X: Vantekai
          </a>
          <a href="https://seize.io/Vantekai" target="_blank" rel="noopener noreferrer">
            Seize: Vantekai
          </a>
          <a href="mailto:admin@6529stats.xyz">
            admin@6529stats.xyz
          </a>
        </div>

        <div className="footer-contact-button">
          <Link to="/contact" className="green-button">
            Contact
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
