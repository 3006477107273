// src/Login.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './Login.css'; // Import the component-specific CSS

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include', // Include credentials
        body: JSON.stringify({ email, password }),
      });
      if (response.ok) {
        setIsAuthenticated(true); // Update auth state
        navigate('/tdh-stats'); // Redirect to a protected page
      } else {
        const data = await response.json();
        setError(data.message || 'An error occurred during login');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred during login');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h2 className="login-title">Login</h2>
        {error && <p className="login-error">{error}</p>}
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="login-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="login-input"
        />
        <button type="submit" className="login-button">
          Log In
        </button>
      </form>
      <p className="login-info-text">
        Login is available to registered users only. Please review the{' '}
        <a
          href="https://6529stats.xyz/subscription"
          target="_blank"
          rel="noopener noreferrer"
          className="subscriptions-link"
        >
          Subscriptions
        </a>{' '}
        page for more information.
      </p>
    </div>
  );
}

export default Login;
