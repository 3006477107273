// src/AboutComponent.js

import React from 'react';
import './AboutComponent.css';

const AboutComponent = () => {
  return (
    <div className="about-container">
      <h1 className="about-main-header">About Us</h1>
      <div className="about-content">
        <p>
          6529 Stats is a website based around the 6529 Memes NFT collection which was created by
          crypto and web3 enthusiast Punk6529 in June 2022.
        </p>
        <p>
          It aims to offer comprehensive and up-to-date data around the collection which at the time
          of writing comprises just over 300 different NFTs.
        </p>
        <p>
          Data is updated as frequently as every 2 minutes and includes information on sales,
          prices, collectors, portfolio valuations, and more.
        </p>
        <p>
          The site was created and is maintained by X and 6529 profile Vantekai who was until recently
          known as web3at50.
        </p>
        <p>
          Regular commentaries and stats can also be found by following Vantekai on X.
        </p>
      </div>
      <div className="about-buttons">
        <a href="https://6529stats.xyz/contact" className="green-button">
          Contact
        </a>
        <a
          href="https://x.com/Vantekai_"
          target="_blank"
          rel="noopener noreferrer"
          className="green-button"
        >
          Follow on X
        </a>
        <a
          href="https://6529.io/Vantekai"
          target="_blank"
          rel="noopener noreferrer"
          className="green-button"
        >
          View 6529 Profile
        </a>
      </div>
    </div>
  );
};

export default AboutComponent;
