// apiService.js

const API_BASE_URL = "https://stats6529backend-f135373a86cd.herokuapp.com/api";

// Function to fetch community members data
export const fetchCommunityMembersData = async (tokenid) => {
    const response = await fetch(`${API_BASE_URL}/communitymembers/${tokenid}`);
    if (!response.ok) {
        throw new Error(`Failed to fetch community members data for token ${tokenid}.`);
    }
    return await response.json();
};

// Existing fetch functions
export const fetchPrices = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    const response = await fetch(`${API_BASE_URL}/prices?${queryString}`);
    if (!response.ok) {
        throw new Error("Failed to fetch prices data.");
    }
    return await response.json();
};

export const fetchSznPrices = async () => {
    const response = await fetch(`${API_BASE_URL}/szn_prices`);
    if (!response.ok) {
        throw new Error("Failed to fetch SZN prices data.");
    }
    return await response.json();
};

export const fetchSznPricesHistory = async () => {
    const response = await fetch(`${API_BASE_URL}/szn_prices_history`);
    if (!response.ok) {
        throw new Error("Failed to fetch SZN prices history data.");
    }
    return await response.json();
};

// Function to fetch sales data
export const fetchSalesData = async (timePeriod) => {
    let endpoint;
    switch (timePeriod) {
        case 'last24hours':
            endpoint = 'sales_last24hours';
            break;
        case 'last7days':
            endpoint = 'sales_last7days';
            break;
        case 'last30days':
            endpoint = 'sales_last30days';
            break;
        case '2022':
            endpoint = 'sales_2022';
            break;
        case '2023':
            endpoint = 'sales_2023';
            break;
        case '2024':
            endpoint = 'sales_2024';
            break;
        case 'all':
        default:
            endpoint = 'sales_all';
            break;
    }
    const response = await fetch(`${API_BASE_URL}/${endpoint}`);
    if (!response.ok) {
        throw new Error(`Failed to fetch sales data for ${timePeriod}.`);
    }
    return await response.json();
};

// Function to fetch sales_bot_AllTime data
export const fetchSalesBotAllTimeData = async (params) => {
    // Remove parameters with undefined, null, or 'undefined' values
    const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
            ([_, v]) => v !== undefined && v !== null && v !== 'undefined' && v !== 'All'
        )
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const response = await fetch(`${API_BASE_URL}/sales_bot_AllTime?${queryString}`);
    if (!response.ok) {
        throw new Error('Failed to fetch sales bot data.');
    }
    const data = await response.json();
    return data;
};

// Function to fetch sales summary based on timeframe or custom date range
export const fetchSalesSummary = async ({ timeFrame, startDate, endDate }) => {
    let url = `${API_BASE_URL}/sales_summary?`;

    if (timeFrame && timeFrame !== 'custom') {
        const validTimeFrames = ['24h', '7d', '30d', 'ytd', 'all'];
        if (!validTimeFrames.includes(timeFrame)) {
            throw new Error('Invalid timeFrame parameter.');
        }
        url += `timeFrame=${timeFrame}`;
    } else if (timeFrame === 'custom') {
        if (!startDate || !endDate) {
            throw new Error('startDate and endDate are required for custom time frame.');
        }
        // Optional: Add frontend validation for date format here
        url += `timeFrame=custom&startDate=${startDate}&endDate=${endDate}`;
    } else {
        throw new Error('Invalid parameters for fetchSalesSummary.');
    }

    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`Failed to fetch sales summary for ${timeFrame}.`);
    }
    return await response.json();
};

// Function to fetch token data
export const fetchTokenData = async (tokenid) => {
    const response = await fetch(`${API_BASE_URL}/token/${tokenid}`);
    if (!response.ok) {
        throw new Error(`Failed to fetch data for token ${tokenid}.`);
    }
    return await response.json();
};

// Function to fetch owner count
export const fetchOwnerCount = async (tokenid) => {
    const response = await fetch(`${API_BASE_URL}/tokenowners?token_id=${tokenid}`);
    if (!response.ok) {
        throw new Error(`Failed to fetch owner count for token ${tokenid}.`);
    }
    const data = await response.json();
    return data.totalOwners;
};

// Function to fetch owners data with pagination
export const fetchOwnersData = async (tokenid, searchTerm = '', page = 1, page_size = 2500) => {
    const params = new URLSearchParams({
        token_id: tokenid,
        page: page,
        page_size: page_size,
    });
    if (searchTerm) {
        params.append('search', searchTerm);
    }
    const response = await fetch(`${API_BASE_URL}/tokenowners?${params.toString()}`);
    if (!response.ok) {
        throw new Error(`Failed to fetch owners data for token ${tokenid}.`);
    }
    const data = await response.json();
    return data; // Return the entire response object
};

// Function to fetch owners aggregated stats data
export const fetchOwnersAggregatedStatsLatest = async () => {
    const response = await fetch(`${API_BASE_URL}/ownersaggregatedstats_latest`);
    if (!response.ok) {
        throw new Error("Failed to fetch owners aggregated stats data.");
    }
    return await response.json();
};

// Function to fetch daily metrics data for Insights page
export const fetchDailyMetrics = async () => {
    const response = await fetch(`${API_BASE_URL}/daily_metrics`);
    if (!response.ok) {
        throw new Error("Failed to fetch daily metrics data.");
    }
    return await response.json();
};

// Function to fetch hodler supply data
export const fetchHodlerSupplyData = async () => {
    const response = await fetch(`${API_BASE_URL}/hodler_supply`);
    if (!response.ok) {
        throw new Error('Failed to fetch hodler supply data.');
    }
    return await response.json();
};

export const fetchHodlerSupplyEnhancedData = async (page = 1, pageSize = 25, sortKey = 'Token', sortDirection = 'ascending', szn = 'All') => {
    const params = new URLSearchParams({
      page: page,
      pageSize: pageSize,
      sortKey: sortKey,
      sortDirection: sortDirection,
    });
    if (szn !== 'All') {
      params.append('szn', szn);
    }
    const response = await fetch(`${API_BASE_URL}/hodler_supply_enhanced?${params.toString()}`);
    if (!response.ok) {
      throw new Error('Failed to fetch hodler supply enhanced data.');
    }
    return await response.json();
  };

// Function to fetch under mint count data
export const fetchUnderMintCount = async () => {
    const response = await fetch(`${API_BASE_URL}/under_mint_count`);
    if (!response.ok) {
        throw new Error('Failed to fetch under mint count data.');
    }
    return await response.json();
};

// Function to fetch owners aggregated stats historical data
export const fetchOwnersAggregatedStatsHistorical = async () => {
    const response = await fetch(`${API_BASE_URL}/ownersaggregatedstatshistorical`);
    if (!response.ok) {
        throw new Error('Failed to fetch owners aggregated stats historical data.');
    }
    return await response.json();
};

// Function to fetch PPTDH data
export const fetchPPTDH = async () => {
    try {
        const response = await fetch('/api/low_pptdh');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching PPTDH data:', error);
        throw error;
    }
};

// Function to fetch monthly PPTDH averages
export const fetchMonthlyPPTDHAverages = async () => {
    const response = await fetch(`${API_BASE_URL}/monthly_pptdh_averages`);
    if (!response.ok) {
        throw new Error('Failed to fetch monthly PPTDH averages.');
    }
    return await response.json();
};
  
