import React, { useState, useEffect } from 'react';
import './HodlerSupplyEnhancedComponent.css';
import { fetchHodlerSupplyEnhancedData } from './apiService';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa'; // Import sort icons

function HodlerSupplyEnhancedComponent() {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'Card No', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 25;

  const [filterConfig, setFilterConfig] = useState({ szn: 'All' });
  const [sznOptions, setSznOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchHodlerSupplyEnhancedData(
          currentPage,
          pageSize,
          sortConfig.key,
          sortConfig.direction,
          filterConfig.szn
        );
        setData(result.data);
        setTotalPages(result.totalPages);
      } catch (error) {
        console.error('Error fetching hodler supply enhanced data:', error);
      }
    };
    fetchData();
  }, [currentPage, sortConfig, filterConfig]);

  // Fetch unique SZN options only once
  useEffect(() => {
    const fetchSznOptions = async () => {
      try {
        const szns = await fetchUniqueSznOptions();
        setSznOptions(szns);
      } catch (error) {
        console.error('Error fetching unique SZN options:', error);
      }
    };
    fetchSznOptions();
  }, []); // Empty dependency array ensures this runs only once

  const fetchUniqueSznOptions = async () => {
    // Fetch unique SZN options from the server
    const sznsData = await fetchHodlerSupplyEnhancedData(1, 1000, 'SZN', 'ascending', 'All');
    const uniqueSzns = [...new Set(sznsData.data.map(item => item['SZN']))];
    return uniqueSzns.sort();
  };

  const requestSort = (key) => {
    if (key === 'Name') return; // 'Name' column is not sortable
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1); // Reset to page 1 when sorting changes
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <FaSort className="hodler-enhanced-sort-icon" />;
    }
    return sortConfig.direction === 'ascending'
      ? <FaSortUp className="hodler-enhanced-sort-icon" />
      : <FaSortDown className="hodler-enhanced-sort-icon" />;
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const columns = [
    { label: 'Card No', key: 'Card No', sortable: true },
    { label: 'Name', key: 'Name', sortable: false },
    { label: 'SZN', key: 'SZN', sortable: true },
    { label: 'Supply', key: 'Supply', sortable: true },
    { label: '6529 Owned', key: '6529 Owned', sortable: true },
    { label: '6529 % Supply', key: '6529 % Supply', sortable: true },
    { label: 'Full Set Cards Owned', key: 'Full Set Cards Owned', sortable: true },
    { label: 'Full Set Holders', key: 'Full Set Holders', sortable: true },
    { label: 'Full Set % Supply', key: 'Full Set % Supply', sortable: true },
    { label: 'SZN Set Cards Owned', key: 'SZN Set Cards Owned', sortable: true },
    { label: 'SZN Set Holders', key: 'SZN Set Holders', sortable: true },
    { label: 'SZN Set % Supply', key: 'SZN Set % Supply', sortable: true },
    { label: 'Remaining Cards', key: 'Remaining Cards', sortable: true },
    { label: 'Other Holders', key: 'Other Holders', sortable: true },
    { label: 'Other % Supply', key: 'Other % Supply', sortable: true },
    { label: 'Available *', key: 'Available *', sortable: true },
    { label: 'Available % **', key: 'Available % **', sortable: true },
  ];

  return (
    <div className="hodler-enhanced-container">
      <h1 className="hodler-enhanced-main-header">Hodler Supply Enhanced</h1>

      {/* Filter Container */}
      <div className="hodler-enhanced-filter-container">
        <div>
          <label htmlFor="szn-filter">Filter by SZN: </label>
          <select
            id="szn-filter"
            value={filterConfig.szn}
            onChange={(e) => {
              setFilterConfig({ ...filterConfig, szn: e.target.value });
              setCurrentPage(1); // Reset to page 1 when filter changes
            }}
          >
            <option value="All">All</option>
            {sznOptions.map((szn) => (
              <option key={szn} value={szn}>
                {szn}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Explanatory Text */}
      <div className="hodler-enhanced-explanations">
        <p>
          <strong>*</strong> Available is calculated by Supply minus 6529 Owned minus number of Full Set Holders minus number of SZN Set Holders
        </p>
        <p>
          <strong>**</strong> Available % is calculated by Available as a percentage of Supply
        </p>
      </div>

      {/* Table */}
      <div className="hodler-enhanced-table-wrapper">
        <table className="hodler-enhanced-centered-table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  onClick={column.sortable ? () => requestSort(column.key) : undefined}
                  className={column.sortable ? 'sortable' : ''}
                >
                  {column.label} {column.sortable && getSortIcon(column.key)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((token, index) => (
              <tr key={index}>
                {columns.map((column) => (
                  <td key={column.key}>{token[column.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="hodler-enhanced-pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default HodlerSupplyEnhancedComponent;
