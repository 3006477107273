// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PricesComponent from './PricesComponent';
import TdhStatsComponent from './TdhStatsComponent';
import TdhTableComponent from './TdhTableComponent';
import PricesHistoryComponent from './PricesHistoryComponent';
import MyPortfolioComponent from './MyPortfolioComponent';
import SalesSummaryComponent from './SalesSummaryComponent';
import SalesBotAllTimeComponent from './SalesBotAllTimeComponent';
import SalesAnalyticsComponent from './SalesAnalyticsComponent';
import NotificationsComponent from './NotificationsComponent';
import FooterComponent from './FooterComponent';
import TokenOwnersComponent from './TokenOwnersComponent';
import HodlerAggregatedComponent from './HodlerAggregatedComponent';
import MyPortfolioBidsComponent from './MyPortfolioBidsComponent';
import HodlerSupplyEnhancedComponent from './HodlerSupplyEnhancedComponent';
import HodlerSupplyComponent from './HodlerSupplyComponent';
import ContactForm from './ContactForm';
import ThankYou from './pages/ThankYou';
import TokenComponent from './TokenComponent';
import InsightsComponent from './InsightsComponent';
import Navbar from './Navbar';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import Login from './Login'; // Import Login component
import PrivateRoute from './PrivateRoute'; // Import PrivateRoute component
import ChangePassword from './ChangePassword'; // Import ChangePassword component (we'll define it next)
import SubscriptionComponent from './SubscriptionComponent';
import AboutComponent from './AboutComponent'; // Import AboutComponent

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <div className="content-wrapper">
            <Navbar />
            <main>
              <Routes>
                <Route path="/" element={<PricesComponent />} />
                <Route path="/insights" element={<InsightsComponent />} />
                <Route
                  path="/tdh-stats"
                  element={
                    <PrivateRoute>
                      <TdhStatsComponent />
                    </PrivateRoute>
                  }
                />
                <Route path="/tdh-table" element={<TdhTableComponent />} />
                <Route path="/prices-history" element={<PricesHistoryComponent />} />
                <Route path="/sales-summary" element={<SalesSummaryComponent />} />
                <Route path="/sales-analytics" element={<SalesAnalyticsComponent />} />
                <Route path="/sales-all-time" element={<SalesBotAllTimeComponent />} />
                <Route path="/portfolio" element={<MyPortfolioComponent />} />
                <Route path="/notifications" element={<NotificationsComponent />} />
                <Route path="/contact" element={<ContactForm />} />
                <Route path="/about" element={<AboutComponent />} /> {/* New route for About */}
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/token/:tokenid" element={<TokenComponent />} />
                <Route path="/token/:tokenid/owners" element={<TokenOwnersComponent />} />
                <Route path="/hodlers/aggregated" element={<HodlerAggregatedComponent />} />
                <Route path="/hodlers/supply" element={<HodlerSupplyComponent />} />
                <Route path="/hodlers/supply-enhanced" element={<HodlerSupplyEnhancedComponent />} />
                <Route path="/my-portfolio-bids" element={<MyPortfolioBidsComponent />} />
                <Route path="/login" element={<Login />} />
                <Route path="/subscription" element={<SubscriptionComponent />} />
                <Route
                  path="/change-password"
                  element={
                    <PrivateRoute>
                      <ChangePassword />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </main>
          </div>
          <FooterComponent />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
