// SubscriptionComponent.js

import React from 'react';
import './SubscriptionComponent.css';

const SubscriptionComponent = () => {
  return (
    <div className="subscription-container">
      <h1 className="subscription-header">Subscription Information</h1>
      <p className="subscription-text">
        Some pages within the website are restricted and only available to users who have subscribed.
      </p>
      <p className="subscription-text">
        Please{' '}
        <a href="/contact" target="_blank" rel="noopener noreferrer">
          contact us
        </a>{' '}
        for details on how to subscribe.
      </p>
    </div>
  );
};

export default SubscriptionComponent;
