// src/ChangePassword.js

import React, { useState } from 'react';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [message, setMessage] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmation) {
      setMessage('New passwords do not match.');
      return;
    }
    try {
      const response = await fetch('/api/change-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ oldPassword, newPassword }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage('Password changed successfully.');
        setOldPassword('');
        setNewPassword('');
        setConfirmation('');
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setMessage('An error occurred.');
    }
  };

  return (
    <form onSubmit={handleChangePassword}>
      <h2>Change Password</h2>
      {message && <p>{message}</p>}
      <input
        type="password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        placeholder="Old Password"
        required
      />
      <input
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="New Password"
        required
      />
      <input
        type="password"
        value={confirmation}
        onChange={(e) => setConfirmation(e.target.value)}
        placeholder="Confirm New Password"
        required
      />
      <button type="submit">Change Password</button>
    </form>
  );
}

export default ChangePassword;
